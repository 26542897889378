import http from '@/plugin/axios'

const orderService = 'boom-center-order-service/app/'
const mixService = 'boom-mix-biz-service/app/' //聚合接口

/**
 * @description: //获取我的订单列表
 * @param {type, page, size}
 */
export function getList(params) {
    return http.get(mixService + 'order/my/v1', { params, isAreaLess: true })
}
/**
 *  订单退款
 * @param {*} id
 * @returns
 */
export function getRefund(params) {
    return http.post(
        orderService + `orderRefund/calculateRefundMoney`,
        { ...params },
        { isAreaLess: true }
    )
}

/**
 * @description: 获取订单详情
 * @param {订单id}
 */
export function getDetail(id) {
    if (!id) {
        return Promise.reject({ msg: 'id不能为空' })
    }
    return http.get(mixService + `order/v1/${id}`, { isAreaLess: true })
}
/**
 * @description: 获取礼包订单详情
 * @param {订单id}
 */
export function getGiftDetail(id) {
    if (!id) {
        return Promise.reject({ msg: 'id不能为空' })
    }
    return http.get(mixService + `order/v1/${id}`, { isAreaLess: true })
}

/**
 * @description: 创建订单 返回支付所需参数-----------------聚合
 * @param {order: {mobile,notes,showMoney},skuList: [ {skuId,skuNum:}]}
 */
export function createH5(params) {
    if (params.sharerMemberId === 'undefined') params.sharerMemberId = 0
    return http.post(mixService + 'order/common', { ...params, orderScene: 1, payChannel: 1 }, { isAreaLess: true }).then(res => {
        res.wxPayH5OrderVO = res.wechatH5
        return res
    })
}

export function create(params) {
    if (params.sharerMemberId === 'undefined') params.sharerMemberId = 0
    return http.post(mixService + 'order/common', { ...params, orderScene: 0 }, { isAreaLess: true }).then(res => {
        res.wxPayMpOrderVO = res.wechatMp
        return res
    })
}

/**
 * @description: 银迅特有接口
 * @param {order: {mobile,notes,showMoney},skuList: [ {skuId,skuNum:} ]}
 */
export function createYinXunH5(params) {
    if (params.sharerMemberId === 'undefined') params.sharerMemberId = 0
    return http.post(mixService + 'order/h5/create/order', { ...params, orderScene: 1 }, { isAreaLess: true }).then(res => {
        return res
    })
}

/**
 * @description: 重新支付
 * @param {订单id}
 */
export function repayH5(id) {
    return http.get(mixService + `order/pay/common/${id}`, { params: { orderScene: 1, payChannel: 1 } }, { isAreaLess: true }).then(res => {
        res.wxPayH5OrderVO = res.wechatH5
        return res
    })
}
export function repay(id) {
    return http.get(mixService + `order/pay/common/${id}`, { params: { orderScene: 0, payChannel: 1 } }, { isAreaLess: true }).then(res => {
        res.wxPayMpOrderVO = res.wechatMp
        return res
    })
}

/**
 * @description: 银迅特有接口
 * @param {订单id}
 */
export function repayYinXun(params) {
    return http.get(mixService + `order/h5/repay/common/${params.orderId}`, { params: { orderScene: 1, payChannel: params.payChannel }}, { isAreaLess: true }).then(res => {
        return res
    })
}

/**
 * @description: 支付完成后更新订单状态
 * @param {订单id，status}
 */
export function updatePayStatus(id, status) {
    return http.put(
        orderService + `order/userPayStatus/${id}?status=${status}`,
        {},
        { isAreaLess: true }
    )
}

/**
 * @description: 自助核销----------------聚合
 * @param {checkCode，storeVerifyCode}
 */
export function selfCheck(params) {
    return http.post(mixService + 'orderCheckCode/selfCheck', { ...params }, { isAreaLess: true })
}

/**
 * @description: 申请退款
 * @param {memberId, orderCheckCodeIds,orderId,orderItemId,refundAmount,refundReason,refundWay}
 */
export function applyRefund(params) {
    return http.post(orderService + 'orderRefund', { ...params }, { isAreaLess: true })
}

//取消退款
export function cancelRefund(params) {
    return http.post(orderService + 'orderRefund/cancel', { ...params }, { isAreaLess: true })
}

/**
 * @description: 查询订单下的全部核销码
 * @param {订单id}
 */
export function getCodeList(params) {
    params.size = 50
    return http.get(mixService + 'orderCheckCode/page/listMyOrderCheckCode', {
        params,
        isAreaLess: true
    })
}

/**
 * @description: 查询礼包订单下的全部核销码
 * @param {订单id}
 */
export function getGiftCodeList(params) {
    params.size = 50
    return http.get(mixService + 'orderCheckCode/page/listMyOrderCheckCode/v1', {
        params,
        isAreaLess: true
    })
}

//商品评论
export function comment(params) {
    return http.post(mixService + 'orderCheckCode/saveReview', params, {
        isAreaLess: true
    })
}

/**
 * @description: 发起预约
 * @param {
  * "bookMobile": "string",
  * "bookName": "string",
  * "bookSeatsNum": 0,
  * "bookStoreId": 0,
  * "bookTimeId": 0,
  * "orderId": 0
    }
 */
export function reserve(params) {
    return http.post(mixService + 'books', params, { isAreaLess: true })
}

export function getReserveCode({ orderId }) {
    return http.get(mixService + `books/bookable-check-codes?orderId=${orderId}`, {
        isAreaLess: true
    })
}

//查询订单支付状态-轮询
export function checkOrderStatus(oid) {
    return http.get(orderService + `order/status/pay/${oid}`, { isAreaLess: true })
}

//查询核销码状态-轮询
export function checkCodeSatus(cid = 0) {
    return http.get(orderService + `order/orderCheckCode/${cid}`, { isAreaLess: true })
}

//获取各状态订单数
export function getOrderStatistics(params) {
    return http.get(orderService + 'order/getOrderStatusBubble', { params, isAreaLess: true })
}
export function deleteOrder(id) {
    return http.delete(orderService + `order/${id}`, { isAreaLess: true })
}

//商品购买列表
export function recentBuyUsers(pid) {
    return http.get(orderService + `order/product/buyer/${pid}`, { isAreaLess: true })
}

// 获取华侨城订单信息状态
export function getOctOtaYoucherStatus(params) {
    return http.get('/boom-mix-third-party-service/app/octOta/voucher/status', { params })
}
