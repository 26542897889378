import { isWeixin, isMiniApp } from './sys'
import fetch from '@/plugin/axios'

let isInit = false
export function init() {
  let appid = 'wx4e37d4087b82a8ae'
  if (process.env.VUE_APP_env === 'prod') {
    appid = 'wx8e4045e2189992f8'
  }
  return new Promise(async (resolve, reject) => {
    fetch
    .post('/boom-center-product-service/wechat/createJsapiSignature', {
      appId: appid,
      url: window.location.href
    })
    .then(res => {
      if(!res) return reject('fail')
      wx.config({
        debug: false,
        appId: res.appId, // 必填，公众号的唯一标识
        timestamp: res.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.nonceStr, // 必填，生成签名的随机串
        signature: res.signature, // 必填，签名
        jsApiList: [
          'openLocation'
        ] // 必填，需要使用的JS接口列表
      })
      // 如果准备好了
      wx.ready(() => {
        isInit = true
        resolve()
      })
    }, reject)
  })
} 

export async function openLocation({ lng, lat, title, address }) {
  // if(isWeixin() || isMiniApp()) {
  //   try {
  //     if(!isInit) await init()
  //     return wx.openLocation({
  //       latitude: lat,
  //       longitude: lng,
  //       name: title,
  //       address
  //     })
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  if(isMiniApp()) return wx.miniProgram.navigateTo({
    url: `/pages/map/index?lng=${lng}&lat=${lat}&title=${title}&address=${address}`
  })
  window.open(`https://apis.map.qq.com/uri/v1/marker?marker=coord:${lat},${lng};title:${title};addr:${address}&referer=SFTBZ-YB5K5-QLBIO-IO5V7-AUEG5-5VBYH`)
}